import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  return (
    <div>
      <div className="sidebar scroll-on-hover">
        <h6 className="m-t-10 m-b-0 m-l-20">Components</h6>
        <ul id="dynamic-tabs">
          <li
            className={
              location.pathname === "/components/muiaccordion" ? "active" : ""
            }>
            <NavLink to="/components/muiaccordion">Accordion</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muialerts" ? "active" : ""
            }>
            <NavLink to="/components/muialerts">Alerts</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muiautocomplete"
                ? "active"
                : ""
            }>
            <NavLink to="/components/muiautocomplete">Autocomplete</NavLink>
          </li>
          {/* <li className={ location.pathname === "/components/muiavatar" ? "active" : "" }>
            <NavLink to="/components/muiavatar" activeClassName="">
            Avatar
            </NavLink>
          </li> */}
          <li
            className={
              location.pathname === "/components/muibadge" ? "active" : ""
            }>
            <NavLink to="/components/muibadge">Badge</NavLink>
          </li>
          <li
            className={location.pathname === "/components/box" ? "active" : ""}>
            <NavLink to="/components/box" activeClassName="">
              Box
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muibreadcrumbs" ? "active" : ""
            }>
            <NavLink to="/components/muibreadcrumbs">Breadcrumbs</NavLink>
          </li>
     
          <li
            className={
              location.pathname === "/components/muibuttons" ? "active" : ""
            }>
            <NavLink to="/components/muibuttons">Button</NavLink>
          </li>

          <li
            className={
              location.pathname === "/components/buttongroup" ? "active" : ""
            }>
            <NavLink to="/components/buttongroup" activeClassName="">
              Button Group
            </NavLink>
          </li>

          <li
            className={
              location.pathname === "/components/card" ? "active" : ""
            }>
            <NavLink to="/components/card">Card</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muicheckbox" ? "active" : ""
            }>
            <NavLink to="/components/muicheckbox">Checkbox</NavLink>
          </li>
          {/* <li className={ location.pathname === "/components/container" ? "active" : "" }>
            <NavLink to="/components/container" activeClassName="">
            Container
            </NavLink>
          </li> */}
          <li
            className={
              location.pathname === "/components/muidatatable" ? "active" : ""
            }>
            <NavLink to="/components/muidatatable"> Data Grid </NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muidatepicker" ? "active" : ""
            }>
            <NavLink to="/components/muidatepicker">Date and Time Picker</NavLink>
          </li>
       

          <li
            className={
              location.pathname === "/components/muidialogs" ? "active" : ""
            }>
            <NavLink to="/components/muidialogs">Dialogs</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muisidebar" ? "active" : ""
            }>
            <NavLink to="/components/muisidebar"> Drawer </NavLink>
          </li>

          <li
            className={
              location.pathname === "/components/muiform" ? "active" : ""
            }>
            <NavLink to="/components/muiform">Form</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/grid" ? "active" : ""
            }>
            <NavLink to="/components/grid">Grid</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muilink" ? "active" : ""
            }>
            <NavLink to="/components/muilink" activeClassName="">
              Link
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/list" ? "active" : ""
            }>
            <NavLink to="/components/list">List</NavLink>
          </li>
          {/* <li className={ location.pathname === "/components/muiloader" ? "active" : "" }>
            <NavLink to="/components/muiloader" activeClassName="">
            Loader
            </NavLink>
          </li> */}

          <li
            className={
              location.pathname === "/components/menu" ? "active" : ""
            }>
            <NavLink to="/components/menu">Menu</NavLink>
          </li>
          
          {/* <li className={ location.pathname === "/components/muibasicgrid" ? "active" : "" }>
            <NavLink to="/components/muibasicgrid" activeClassName="">
            MUI Grid
            </NavLink>
          </li> */}
          <li
            className={
              location.pathname === "/components/muipopover" ? "active" : ""
            }>
            <NavLink to="/components/muipopover">Popover</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/select" ? "active" : ""
            }>
            <NavLink to="/components/select" activeClassName="">
              Select
            </NavLink>
          </li>

          <li
            className={
              location.pathname === "/components/sidenavigation" ? "active" : ""
            }>
            <NavLink to="/components/sidenavigation" activeClassName="">
              Side Navigation
            </NavLink>
          </li>
          {/* <li className={ location.pathname === "/components/skeleton" ? "active" : "" }>
            <NavLink to="/components/skeleton" activeClassName="">
            Skeleton
            </NavLink>
          </li>
          <li className={ location.pathname === "/components/stack" ? "active" : "" }>
            <NavLink to="/components/stack" activeClassName="">
            Stack
            </NavLink>
          </li> */}
          <li
            className={
              location.pathname === "/components/stepper" ? "active" : ""
            }>
            <NavLink to="/components/stepper">Stepper</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muiswitch" ? "active" : ""
            }>
            <NavLink to="/components/muiswitch">Switch</NavLink>
          </li>
    

          <li
            className={
              location.pathname === "/components/muitabs" ? "active" : ""
            }>
            <NavLink to="/components/muitabs">Tabs</NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/textareaautosize"
                ? "active"
                : ""
            }>
            <NavLink to="/components/textareaautosize">
              Textarea Autosize
            </NavLink>
          </li>
          {/* <li className={ location.pathname === "/components/muitogglebutton" ? "active" : "" }>
            <NavLink to="/components/muitogglebutton" activeClassName="">
            Toggle Button
            </NavLink>
          </li> */}
              <li
            className={
              location.pathname === "/components/muitoaster" ? "active" : ""
            }>
            <NavLink to="/components/muitoaster" activeClassName="">
              Toaster
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/muitooltip" ? "active" : ""
            }>
            <NavLink to="/components/muitooltip" activeClassName="">
              Tooltip
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/components/transferlist" ? "active" : ""
            }>
            <NavLink to="/components/transferlist" activeClassName="">
              Transfer List
            </NavLink>
          </li>


          <li
            className={
              location.pathname === "/components/treeviewlist" ? "active" : ""
            }>
            <NavLink to="/components/treeviewlist" activeClassName="">
              Tree View
            </NavLink>
          </li>

  
   

          <li
            className={
              location.pathname === "/components/typography" ? "active" : ""
            }>
            <NavLink to="/components/typography" activeClassName="">
              Typography
            </NavLink>
          </li>
      
          {/* <li
            className={
              location.pathname === "/components/slider" ? "active" : ""
            }>
            <NavLink to="/components/slider" activeClassName="">
            Slider
            </NavLink>
          </li> */}
          {/* <li
            className={
              location.pathname === "/components/progress" ? "active" : ""
            }>
            <NavLink to="/components/progress" activeClassName="">
            Progress
            </NavLink>
          </li> */}
      
      
       
      
     
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
