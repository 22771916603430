import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Listview from './Listview';
 

// Update this part to use createRoot and render
const root = ReactDOM.createRoot(document.getElementById('root'));

// Use the render method to render your app
root.render(<App />);
//import reportWebVitals from './reportWebVitals';
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <div>
//     <App/>
//     </div>
//    </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
