import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import CollapsibleCode from "./CodeBlockcode";

import { CopyBlock, dracula } from "react-code-blocks";
const label = { inputProps: { "aria-label": "Switch demo" } };
const MuiRightlist = () => {
  // Active class for bookmarks
  const [activeElement, setActiveElement] = useState(0);

  const handleItemClick = (index) => {
    setActiveElement(index);
  };

  // React Code Block Starts
  const [language, changeLanguage] = useState("js");
  const [lineNumbers, toggleLineNumbers] = useState(true);

  const SidenavigationCodetwo = ` // To use mui list and tree view import list and tree view component from mui //
    import Box from "@mui/material/Box";
    import List from "@mui/material/List";
    import ListSubheader from "@mui/material/ListSubheader";
    import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
    import ChevronRightIcon from '@mui/icons-material/ChevronRight';
    import { TreeView } from '@mui/x-tree-view/TreeView';
    import { TreeItem } from '@mui/x-tree-view/TreeItem';

  // React MUI component code//
  export default function list() {
    return (
        <Box className="sidenavigation-component">
        <List
          className="rightsidesearch-list"
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="sidenavigation-heading">
              {" "}
              <span>Side Navigation</span>
              <input
                type="search"
                name="q"
                class="sidenavigation-search-text"
                placeholder="Search..."
                autocomplete="off"
              />
            </ListSubheader>
          }>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}>
            {" "}
            <TreeItem
              nodeId="0"
              label="Applications Info"></TreeItem>
            <TreeItem nodeId="1" label="Applications">
              <TreeItem nodeId="2" label="Calendar" />
            </TreeItem>
            <TreeItem nodeId="5" label="Documents">
              <TreeItem nodeId="10" label="OSS" />
            </TreeItem>
          </TreeView>
        </List>
      </Box>
    );
  }
    `;

  // React Code Block End here

  // Code for collapse Starts
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  // Code for collapse End here

  const Sidenavigationtwo = {
    code: (
      <CopyBlock
        language={language}
        text={SidenavigationCodetwo}
        showLineNumbers={lineNumbers}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
    ),
  };
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="">
      <div className="content-header">
        <h1 className="heading"> Side Navigation </h1>

        <p className="text m-b-0">
          Lists are a continuous group of text or images. They are composed of
          items containing primary and supplemental actions, which are
          represented by icons and text.
        </p>
      </div>
      <div className="content-wrapper">
        <div className="content-area">
          <div className="row">
            <div className="col-12">
              <section>
                <h3 className="sub-heading">Component Usage </h3>
                <p className="text">
                  To use design system styles, import below file to your App /
                  Component
                </p>

                <div className="code-container-import">
                  <pre>
                    <code>
                      <div className="m-l-15">
                        @import
                        url("https://freyadesignsystemurl/design/list.css");
                      </div>
                    </code>
                  </pre>
                </div>
              </section>
            </div>
          </div>

          <hr className="section-border-bottom"></hr>

          <div className="row">
            <div className="col-12 ">
              <section>
                <h3 className="sub-heading"> Side Navigation</h3>
                <p className="text">
                  Lists are continuous, vertical indexes of text or images.
                </p>
                <div className="collapsible-code">
                  <div className="row ">
                    <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20 ">
                      <Box className="sidenavigation-component">
                        <List
                          className="rightsidesearch-list"
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          subheader={
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                              className="sidenavigation-heading">
                              {" "}
                              <span>Side Navigation</span>
                              <input
                                type="search"
                                name="q"
                                class="sidenavigation-search-text"
                                placeholder="Search..."
                                autocomplete="off"
                              />
                            </ListSubheader>
                          }>
                          <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}>
                            {" "}
                            <TreeItem
                              nodeId="0"
                              label="Applications Info"></TreeItem>
                            <TreeItem nodeId="1" label="Applications">
                              <TreeItem nodeId="2" label="Calendar" />
                            </TreeItem>
                            <TreeItem nodeId="5" label="Documents">
                              <TreeItem nodeId="10" label="OSS" />
                            </TreeItem>
                          </TreeView>
                        </List>
                      </Box>
                    </div>
                  </div>
                  <CollapsibleCode codeItem={Sidenavigationtwo} />
                </div>
              </section>
            </div>
          </div>

          <hr className="section-border-bottom"></hr>

          <div className="row">
            <div className="col-12 m-t-20">
              <h3 className="sub-heading">
                React Material UI Component Library{" "}
              </h3>
              <p className="text">
                For more information, you may refer MUI component page by
                clicking below link.
              </p>

              <div className="notification-main">
                <div className="notification-content">
                  <a
                    href="https://mui.com/material-ui/react-list/"
                    target="blank">
                    MUI List
                  </a>{" "}
                  <br></br>
                  <a href="https://mui.com/x/react-tree-view//" target="blank">
                    MUI Tree View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bookmark-navigator">
      </div> */}
      </div>
    </div>
  );
};

export default MuiRightlist;
