import React, { useEffect, useState } from "react";
 import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
 import Alert from '@mui/material/Alert';


import CollapsibleCode from "./CodeBlockcode";
import { CopyBlock, dracula } from "react-code-blocks";
import Toaster from "./toaster";



const label = { inputProps: { "aria-label": "Switch demo" } };
const Toasters = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Active class for bookmarks
    const [activeElement, setActiveElement] = useState(0);

    const handleItemClick = (index) => {
        setActiveElement(index);
    };
    const items = ["Default", "Primary", "Outlined", "Link Button"];

    // React Code Block Starts
    const [language, changeLanguage] = useState("js");
    const [lineNumbers, toggleLineNumbers] = useState(true);
    const MuiselectCode = ` // To use mui snackbar import snackbar component from mui // 

 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
 import Alert from '@mui/material/Alert';
  
  
   // React MUI component code// 
   export default function BasicTabs() {
    return (
  <Box sx={{ width: 500 }}>  {buttons}  </Box>
       <Snackbar anchorOrigin={{ vertical, horizontal }} open={openerror} autoHideDuration={6000} onClose={errorClose}  key={vertical + horizontal}>
        <Alert
          onClose={errorClose}
          severity="error"
          variant="filled">
          This is a error Alert inside a Snackbar!
        </Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openwarning} autoHideDuration={6000} onClose={warningClose}  key={vertical + horizontal}>
        <Alert
          onClose={warningClose}
          severity="warning"
          variant="filled">
          This is a warning Alert inside a Snackbar!
        </Alert>
      </Snackbar>
      
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={opensuccess} autoHideDuration={6000} onClose={successClose}  key={vertical + horizontal}>
        <Alert
          onClose={successClose}
          severity="success"
          variant="filled">
          This is a success Alert inside a Snackbar!
        </Alert>
      </Snackbar>

       
    
        );
    } 
  `;

    // React Code Block End here

    // Code for collapse Starts
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Code for collapse End here

    const Muiselect = {
        code: (
            <CopyBlock
                language={language}
                text={MuiselectCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const [state, setState] = React.useState({
        openerror: false,
        openwarning: false,
        opensuccess: false,
         vertical: 'top',
        horizontal: 'center',
      });
   
      const { vertical, horizontal, openerror, openwarning, opensuccess} = state;
      
    
      const errorClick = (newState) => () => {
        setState({ ...newState, openerror: true });
      };
      const warningClick = (newState) => () => {
        setState({ ...newState, openwarning: true });
      };
      const successClick = (newState) => () => {
        setState({ ...newState, opensuccess: true });
      };

     
     
    
      const errorClose = () => {
        setState({ ...state, openerror: false });
      };
      const warningClose = () => {
        setState({ ...state, openwarning: false });
      };
     
     const successClose = () => {
        setState({ ...state, opensuccess: false });
      };
      
     
      const buttons = (
        <React.Fragment>
          <Box>
            <Button onClick={errorClick({ vertical: 'top', horizontal: 'center' })}>
              Error 
            </Button>
            <Button onClick={warningClick({ vertical: 'top', horizontal: 'center' })}>
              Warning 
            </Button>
            <Button onClick={successClick({ vertical: 'top', horizontal: 'center' })}>
              Success 
            </Button>
          
          
          </Box>
         
        </React.Fragment>
      );
    
  

    return (
        <div>
            <div className="content-header">
                <h1 className="heading"> Snackbar
 </h1>

                <p className="text m-b-0">
                Snackbars (also known as toasts) are used for brief notifications of processes that have been or will be performed.
                </p>
            </div>
            <div className="content-wrapper">
                <div className="content-area">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <h3 className="sub-heading">Component Usage </h3>
                                <p className="text">
                                    To use design system styles, import below file to your App /
                                    Component
                                </p>

                                <div className="code-container-import">
                                    <pre>
                                        <code>
                                            <div className="m-l-15">
                                                @import
                                                url("https://freyadesignsystemurl/design/tabs.css");
                                            </div>
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>

                    {/*Muiselect Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Snackbar </h3>
                                <p className="text">
                                The Snackbar component appears temporarily and floats above the UI to provide users with (non-critical) updates on an app's processes.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                        <Box sx={{ width: 500 }}>
      {buttons}
      
    </Box>
    

    <Snackbar anchorOrigin={{ vertical, horizontal }} open={openerror} autoHideDuration={6000} onClose={errorClose}  key={vertical + horizontal}>
        <Alert
          onClose={errorClose}
          severity="error"
          variant="filled"
        >
          This is a error Alert inside a Snackbar!
        </Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openwarning} autoHideDuration={6000} onClose={warningClose}  key={vertical + horizontal}>
        <Alert
          onClose={warningClose}
          severity="warning"
          variant="filled"
        >
          This is a warning Alert inside a Snackbar!
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={opensuccess} autoHideDuration={6000} onClose={successClose}  key={vertical + horizontal}>
        <Alert
          onClose={successClose}
          severity="success"
          variant="filled"
        >
          This is a success Alert inside a Snackbar!
        </Alert>
      </Snackbar>
     
        

                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={Muiselect} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Muiselect Section Ends here */}

                    <hr className="section-border-bottom"></hr>

                    <div className="row">
                        <div className="col-12 m-t-20">
                            <h3 className="sub-heading">
                                React Material UI Component Library{" "}
                            </h3>
                            <p className="text">
                                For more information, you may refer MUI component page by
                                clicking below link.
                            </p>

                            <div className="notification-main">
                                <div className="notification-content">
                                    <a
                                        href="https://mui.com/material-ui/react-snackbar/"
                                        target="blank"
                                    >
                                        MUI Snackbar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Toasters;
