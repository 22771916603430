import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';


import CollapsibleCode from "./CodeBlockcode";
import { CopyBlock, dracula } from "react-code-blocks";



const label = { inputProps: { "aria-label": "Switch demo" } };
const Muiboxcomponent = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Active class for bookmarks
    const [activeElement, setActiveElement] = useState(0);

    const handleItemClick = (index) => {
        setActiveElement(index);
    };
    const items = ["Default", "Primary", "Outlined", "Link Button"];

    // React Code Block Starts
    const [language, changeLanguage] = useState("js");
    const [lineNumbers, toggleLineNumbers] = useState(true);
    const muiboxCode = ` // To use mui box import box component from mui // 
    import Box from '@mui/material/Box';
  
   // React MUI component code// 
   export default function BasicBox() {
    return (
        <Box component="section" className="box-component">
        This Box renders as an HTML section element.
    </Box>
        );
    } 
  `;

    // React Code Block End here

    // Code for collapse Starts
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Code for collapse End here

    const box = {
        code: (
            <CopyBlock
                language={language}
                text={muiboxCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };


    return (
        <div>
            <div className="content-header">
                <h1 className="heading"> Box </h1>

                <p className="text m-b-0">
                    The Box component is a generic, theme-aware container with access to CSS utilities from MUI System.
                </p>
            </div>
            <div className="content-wrapper">
                <div className="content-area">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <h3 className="sub-heading">Component Usage </h3>
                                <p className="text">
                                    To use design system styles, import below file to your App /
                                    Component
                                </p>

                                <div className="code-container-import">
                                    <pre>
                                        <code>
                                            <div className="m-l-15">
                                                @import
                                                url("https://freyadesignsystemurl/design/tabs.css");
                                            </div>
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>

                    {/*Box Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Box </h3>
                                <p className="text">
                                    The Box component is a generic, theme-aware container with access to CSS utilities from MUI System.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                            <Box component="section" className="box-component">
                                                This Box renders as an HTML section element.
                                            </Box>



                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={box} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Box Section Ends here */}

                    <hr className="section-border-bottom"></hr>

                    <div className="row">
                        <div className="col-12 m-t-20">
                            <h3 className="sub-heading">
                                React Material UI Component Library{" "}
                            </h3>
                            <p className="text">
                                For more information, you may refer MUI component page by
                                clicking below link.
                            </p>

                            <div className="notification-main">
                                <div className="notification-content">
                                    <a
                                        href="https://mui.com/material-ui/react-box/"
                                        target="blank"
                                    >
                                        MUI Box
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Muiboxcomponent;
