const Grid = () => {
  return (
    <div>
      <div className="content-header">
        <h1 className="heading"> Grid</h1>
        <p className="text">
          The Material Design responsive layout grid adapts to screen size and
          orientation, ensuring consistency across layouts.
        </p>
      </div>
      <div className="content-wrapper">
        <div className="content-area">
          <center>
            <div className="container">
              <div className="row">
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
                <div className="col col-1 box">col 1</div>
              </div>
              <div className="row">
                <div className="col col-2 box">col 2</div>
                <div className="col col-2 box">col 2</div>
                <div className="col col-2 box">col 2</div>
                <div className="col col-2 box">col 2</div>
                <div className="col col-2 box">col 2</div>
                <div className="col col-2 box">col 2</div>
              </div>
              <div className="row">
                <div className="col col-3 box">col 3</div>
                <div className="col col-3 box">col 3</div>
                <div className="col col-3 box">col 3</div>
                <div className="col col-3 box">col 3</div>
              </div>
              <div className="row">
                <div className="col col-4 box">col 4</div>
                <div className="col col-4 box">col 4</div>
                <div className="col col-4 box">col 4</div>
              </div>
              <div className="row">
                <div className="col col-5 box">col 5</div>
                <div className="col col-5 box">col 5</div>
                <div className="col col-2 box">col 2</div>
              </div>
              <div className="row">
                <div className="col col-6 box">col 6</div>
                <div className="col col-6 box">col 6</div>
              </div>
              <div className="row">
                <div className="col col-7 box">col 7</div>
                <div className="col col-5 box">col 5</div>
              </div>
              <div className="row">
                <div className="col col-8 box">col 8</div>
                <div className="col col-4 box">col 4</div>
              </div>
              <div className="row">
                <div className="col col-9 box">col 9</div>
                <div className="col col-3 box">col 3</div>
              </div>
              <div className="row">
                <div className="col col-10 box">col 10</div>
                <div className="col col-2 box">col 2</div>
              </div>
              <div className="row">
                <div className="col col-11 box">col 11</div>
                <div className="col col-1 box">col 1</div>
              </div>
              <div className="row">
                <div className="col col-12 box">col 12</div>
              </div>
              <br></br>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Grid;
