import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import CollapsibleCode from "./CodeBlockcode";
import { CopyBlock, dracula } from "react-code-blocks";



const label = { inputProps: { "aria-label": "Switch demo" } };
const Muiselectcomponent = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Active class for bookmarks
    const [activeElement, setActiveElement] = useState(0);

    const handleItemClick = (index) => {
        setActiveElement(index);
    };
    const items = ["Default", "Primary", "Outlined", "Link Button"];

    // React Code Block Starts
    const [language, changeLanguage] = useState("js");
    const [lineNumbers, toggleLineNumbers] = useState(true);
    const MuiselectCode = ` // To use mui select import select component from mui // 

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
  
  
   // React MUI component code// 
   export default function Basicselect() {
    return (

        <Box >
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChangeone}
            >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
            </Select>
        </FormControl>
    </Box>
    
        );
    } 
  `;

    // React Code Block End here

    // Code for collapse Starts
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Code for collapse End here

    const Muiselect = {
        code: (
            <CopyBlock
                language={language}
                text={MuiselectCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const [age, setAge] = React.useState('');

    const handleChangeone = (event) => {
        setAge(event.target.value);
      };

    return (
        <div>
            <div className="content-header">
                <h1 className="heading"> Select </h1>

                <p className="text m-b-0">
                    Select components are used for collecting user provided information from a list of options.
                </p>
            </div>
            <div className="content-wrapper">
                <div className="content-area">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <h3 className="sub-heading">Component Usage </h3>
                                <p className="text">
                                    To use design system styles, import below file to your App /
                                    Component
                                </p>

                                <div className="code-container-import">
                                    <pre>
                                        <code>
                                            <div className="m-l-15">
                                                @import
                                                url("https://freyadesignsystemurl/design/tabs.css");
                                            </div>
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>

                    {/*Muiselect Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Select </h3>
                                <p className="text">
                                    Select components are used for collecting user provided information from a list of options.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                            <Box  >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        label="Age"
                                                        onChange={handleChangeone}
                                                    >
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>



                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={Muiselect} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Muiselect Section Ends here */}

                    <hr className="section-border-bottom"></hr>

                    <div className="row">
                        <div className="col-12 m-t-20">
                            <h3 className="sub-heading">
                                React Material UI Component Library{" "}
                            </h3>
                            <p className="text">
                                For more information, you may refer MUI component page by
                                clicking below link.
                            </p>

                            <div className="notification-main">
                                <div className="notification-content">
                                    <a
                                        href="https://mui.com/material-ui/react-select/"
                                        target="blank"
                                    >
                                        MUI Select
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Muiselectcomponent;
