import React, { useState } from "react";

function ListItem({ text }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      const htmlText = `<em className="${text}"></em>`; // Wrap text in <div> tags
      await navigator.clipboard.writeText(htmlText);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <li>
      <button
        onClick={handleCopy}
        className="tooltip tooltip-sm bottom"
        data-tooltip="Copy Code Snippet">
        <em className="freyafusion-file-11"></em>
      </button>
      <em className={text}></em>
      <span>{text} </span>
      {copied && <span class="code-copied">Copied</span>}
    </li>
  );
}

export default ListItem;
