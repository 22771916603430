// About.js
import React from 'react';
import IconList from '../pages/iconslist';
const Icons = () => {
  return (
    <content className='container'>

<IconList></IconList>
   </content>
  )
};

export default Icons;