import React, { useEffect, useState } from "react";
import Avatar from "../assets/images/avatar.svg";
import Accordion from "../assets/images/accordion.png";
import Alerts from "../assets/images/alerts.png";
import Autocomplete from "../assets/images/autocomplete.png";
import Badges from "../assets/images/badges.png";
import Breadcrumbsimage from "../assets/images/breadcrumbsimage.png";
import Button from "../assets/images/button.png";
import Card from "../assets/images/card.png";
import Checkbox from "../assets/images/checkbox.png";
import Dialog from "../assets/images/dialog.png";
import Form from "../assets/images/form.png";
import Grid from "../assets/images/grid.png";
import List from "../assets/images/list.png";
import Menu from "../assets/images/menu.png";
import Popover from "../assets/images/popover.png";
import SidebarImg from "../assets/images/sidebar.png";
import Switch from "../assets/images/switch.png";
import Tabs from "../assets/images/tabs.png";
import Textarea from "../assets/images/textarea.png";
import Tooltip from "../assets/images/tooltip.png";
import Typography from "../assets/images/typography.png";
import Box from "../assets/images/box.png";
import Buttongroup from "../assets/images/buttongroup.png";
import Muilink from "../assets/images/muilink.png";
import Treeview from "../assets/images/treeview.png";
import Transferlist from "../assets/images/transferlist.png";
// import Container from "../assets/images/container.png";
// import Muigrid from "../assets/images/muigrid.png";
// import Stack from "../assets/images/stack.png";
// import Avatargroup from "../assets/images/avatargroup.png";
// import Togglebutton from "../assets/images/togglebutton.png";
// import Loader from "../assets/images/loader.png";
// import Skeleton from "../assets/images/skeleton.png";






import Datapicker from "../assets/images/datapicker.png";
import Datatable from "../assets/images/datatable.png";
import Sidenavigation from "../assets/images/sidenavigation.png";
import Stepper from "../assets/images/stepper.png";
import Muitoaster from "../assets/images/toaster.png";

import { CopyBlock, dracula } from "react-code-blocks";
import CollapsibleCode from "./CodeBlockcode";
import { CheckBox } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";

import { Link } from "react-router-dom";

const Muiform = () => {
  const [isOpen, setOpen] = useState(false);
  // Active class for bookmarks
  const [activeElement, setActiveElement] = useState(0);
  const handleItemClick = (index) => {
    setActiveElement(index);
  };

  const [currentPage, setCurrentPage] = useState("muiaccordion");
  // React Code Block Starts

  const [language, changeLanguage] = useState("js");
  const [lineNumbers, toggleLineNumbers] = useState(true);

  // Start Checkboxes

  // React Code Block End here

  // Code for collapse Starts

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Code for collapse End here

  return (
    <div>
      <div className="content-header">
        <div className="row">
          <div className="col-md-6">
            <h1 className="heading">Components</h1>
            <p className="text">
              Components are the reusable building blocks of our design system.
              Each component meets a specific interaction or UI need, and has
              been specifically created to work together to create patterns and
              intuitive user experiences.
            </p>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="content-area component_main">
          <div className="row">
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiaccordion"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Accordion} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Accordion </h2>
                    </div>
                    <span class="component-content-text">
                      The Accordion component lets users show and hide sections
                      of related content on a page.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muialerts"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Alerts} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Alert </h2>
                    </div>
                    <span class="component-content-text">
                      A flag is used for confirmations, alerts, and
                      acknowledgments that require minimal user interaction,
                      often displayed using a flag group.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiautocomplete"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Autocomplete} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Autocomplete </h2>
                    </div>
                    <span class="component-content-text">
                      The autocomplete is a normal text input enhanced by a
                      panel of suggested options.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

          </div>

          <div className="row">

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muibadge"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Badges} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Badge </h2>
                    </div>
                    <span class="component-content-text">
                      A badge is a visual indicator for numeric values such as
                      tallies and scores.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/box"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Box} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Box </h2>
                    </div>
                    <span class="component-content-text">

                      The Box component is a generic container for grouping other components. It's a fundamental building block.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muibreadcrumbs"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Breadcrumbsimage} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Breadcrumbs </h2>
                    </div>
                    <span class="component-content-text">
                      Breadcrumbs are a navigation system used to show a user's
                      location in a site or app.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

          </div>
          <div className="row">


            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muibuttons"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Button} height="auto" width="100%" />
                    {/* <img src="assets/images/avatar.svg" class="component-image" /> */}
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Button </h2>
                    </div>
                    <span class="component-content-text">
                      A button triggers an event or action. They let users know
                      what will happen next.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/buttongroup"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Buttongroup} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Button Group </h2>
                    </div>
                    <span class="component-content-text">

                      The buttons can be grouped by wrapping them with the button group component. They need to be immediate children.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/card"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Card} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Card</h2>
                    </div>
                    <span class="component-content-text">
                      Cards are surfaces that display content and actions on a
                      single topic.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row">
               <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muicheckbox"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Checkbox} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Checkbox</h2>
                    </div>
                    <span class="component-content-text">
                      A checkbox is an input control that allows a user to
                      select one or more options from a number of choices.
                    </span>
                  </div>
                </div>
              </Link>
               </div>


            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muidatatable"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Datatable} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Data Grid</h2>
                    </div>
                    <span class="component-content-text">
                      A fast and extendable react data grid and react data grid. It's a feature-rich component available in MIT or Commercial versions.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muidatepicker"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Datapicker} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Date and Time Picker</h2>
                    </div>
                    <span class="component-content-text">
                      The Date and Time Picker component lets users select a date and time.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

        </div>

          <div className="row">



            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muidialogs"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Dialog} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Dialogs </h2>
                    </div>
                    <span class="component-content-text">
                      Dialogs inform users about a task and can contain critical
                      information, require decisions, or involve multiple tasks.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muisidebar"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={SidebarImg} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Drawer </h2>
                    </div>
                    <span class="component-content-text">
                      A drawer is a sliding panel that enters from the right edge of the viewport. It can be configured to either overlay content on a page or create a sidebar by pushing that content to the left.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiform"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Form} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Form</h2>
                    </div>
                    <span class="component-content-text">
                      A form allows users to input information.
                    </span>
                  </div>
                </div>
              </Link>
            </div>



          </div>

          <div className="row">


            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/grid"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Grid} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Grid</h2>
                    </div>
                    <span class="component-content-text">
                      A grid uses the CSS grid API to create consistent layouts.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muilink"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Muilink} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Link </h2>
                    </div>
                    <span class="component-content-text">

                      The Link component allows you to easily customize anchor elements with your theme colors and typography styles.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/list"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={List} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">List</h2>
                    </div>
                    <span class="component-content-text">
                      Lists are a continuous group of text or images. They are
                      composed of items containing primary and supplemental
                      actions.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

          </div>



          <div className="row">
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/menu"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Menu} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Menu</h2>
                    </div>
                    <span class="component-content-text">
                      A dropdown menu displays a list of actions or options to a
                      user.
                    </span>
                  </div>
                </div>
              </Link>
            </div>


            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muipopover"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Popover} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Popover</h2>
                    </div>
                    <span class="component-content-text">
                      A Popover can be used to display some content on top of
                      another.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/sidenavigation"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Sidenavigation} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Side Navigation</h2>
                    </div>
                    <span class="component-content-text">
                      Lists are a continuous group of text or images. They are composed of items containing primary and supplemental actions, which are represented by icons and text.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

          </div>


          <div className="row">
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/stepper"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Stepper} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Stepper</h2>
                    </div>
                    <span class="component-content-text">
                      Steppers convey progress through numbered steps. It provides a wizard-like workflow.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiswitch"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Switch} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Switch </h2>
                    </div>
                    <span class="component-content-text">
                      A switch is used to view or switch between enabled or
                      disabled states.
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muitabs"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Tabs} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Tabs </h2>
                    </div>
                    <span class="component-content-text">
                      Tabs are used to organize content by grouping similar
                      information on the same page.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/textareaautosize"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Textarea} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Textarea Autosize</h2>
                    </div>
                    <span class="component-content-text">
                      Use the minrows prop to define the minimum height of the
                      component.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muitoaster"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Muitoaster} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Toaster</h2>
                    </div>
                    <span class="component-content-text">
                      Snackbars  are used for brief notifications of processes that have been or will be performed.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muitooltip"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Tooltip} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Tooltip</h2>
                    </div>
                    <span class="component-content-text">
                      A tooltip is a floating, non-actionable label used to
                      explain a user interface element or feature.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          
          </div>
          <div className="row">
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/transferlist"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Transferlist} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Transfer List </h2>
                    </div>
                    <span class="component-content-text">

                      A Transfer list enables the user to move one or more list items between lists.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/treeviewlist"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Treeview} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Tree View </h2>
                    </div>
                    <span class="component-content-text">

                      Tree views can be used to represent a file system navigator displaying folders and files, an item representing a folder can be expanded to reveal the contents of the folder, which may be files, folders, or both.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/typography"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Typography} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Typography</h2>
                    </div>
                    <span class="component-content-text">
                      Typography is our system of fonts. Each font conveys the appropriate sentiment to assist our users through each stage of their journey.
                    </span>
                  </div>
                </div>
              </Link>
            </div>



          </div>

<div className="row">
   {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiavatar"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Avatargroup} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Avatar </h2>
                    </div>
                    <span class="component-content-text">

                      Avatars are found throughout material design with uses in everything from tables to dialog menus.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}

          {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/container"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Container} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Container </h2>
                    </div>
                    <span class="component-content-text">

                      The container centers your content horizontally. It's the most basic layout element.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}


          {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muibasicgrid"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Muigrid} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> MUI Grid </h2>
                    </div>
                    <span class="component-content-text">

                      The Material Design responsive layout grid adapts to screen size and orientation, ensuring consistency across layouts.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}

</div>

<div className="row">
         
          {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/skeleton"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Skeleton} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Skeleton </h2>
                    </div>
                    <span class="component-content-text">

                      Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}


          {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/stack"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Stack} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Stack </h2>
                    </div>
                    <span class="component-content-text">

                      Stack is a container component for arranging elements vertically or horizontally.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}



          {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muiloader"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Loader} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Loader </h2>
                    </div>
                    <span class="component-content-text">

                      A Toggle Button can be used to group related options.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}

</div>


         



           <div className="row">

             {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/muitogglebutton"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Togglebutton} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text"> Toggle Button </h2>
                    </div>
                    <span class="component-content-text">

                      A Toggle Button can be used to group related options.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}
          
           {/* <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/typography"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Typography} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Slider</h2>
                    </div>
                    <span class="component-content-text">
                    Slider generates a background element that can be used for various purposes.
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4 m-20">
              <Link
                activeClassName="is-active"
                to="/components/typography"
                className="design-system-component"
              >
                <div className="component-innerbox">
                  <div className="component-image-box">
                    <img src={Typography} height="auto" width="100%" />
                    <div class="component-heading">
                      <h2 class="component-heading-text">Progress</h2>
                    </div>
                    <span class="component-content-text">
                    Progress indicators inform users about the status of ongoing processes, such as loading an app, submitting a form, or saving updates.
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}
           </div> 

        </div>
      </div>
    </div>
  );
};

export default Muiform;
