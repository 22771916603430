import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';



import CollapsibleCode from "./CodeBlockcode";

import { CopyBlock, dracula } from "react-code-blocks";
const label = { inputProps: { "aria-label": "Switch demo" } };

const MuiTreeview = () => {
    // Active class for bookmarks
    const [activeElement, setActiveElement] = useState(0);

    const handleItemClick = (index) => {
        setActiveElement(index);
    };

    // React Code Block Starts
    const [language, changeLanguage] = useState("js");
    const [lineNumbers, toggleLineNumbers] = useState(true);



const TreeviewlistCode = ` // To use mui tree view  import treeview component from mui //

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

// React MUI component code// 
export default function BasicTreeviewlist() {
  return (
<Box  className="treeview-list" sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
<TreeView 
    aria-label="file system navigator"
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
>
    <TreeItem nodeId="1"  label="Treeview List">
    
        <TreeItem nodeId="2" label="Sub Treeview List" />
    </TreeItem>
    <TreeItem nodeId="5" label="Item">
        <TreeItem nodeId="10" label="Item 1" />
        <TreeItem nodeId="6" label="Sub Item">
            <TreeItem nodeId="8" label="Sub Item 1" />
        </TreeItem>
    </TreeItem>
</TreeView>
</Box>
);
} 
 
    `;

    // React Code Block End here

    // Code for collapse Starts
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Code for collapse End here


    const Treeviewlist = {
        code: (
            <CopyBlock
                language={language}
                text={TreeviewlistCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    // const [selectedIndex, setSelectedIndex] = React.useState(1);

    // const handleListItemClick = (event, index) => {
    //     setSelectedIndex(index);
    // };
    // const [open, setOpen] = React.useState(true);

    // const handleClick = () => {
    //     setOpen(!open);
    // };
    return (
        <div className="">
            <div className="content-header">
                <h1 className="heading"> Tree View </h1>

                <p className="text m-b-0">
                    Tree views can be used to represent a file system navigator displaying folders and files, an item representing a folder can be expanded to reveal the contents of the folder, which may be files, folders, or both.
                </p>
            </div>
            <div className="content-wrapper">
                <div className="content-area">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <h3 className="sub-heading">Component Usage </h3>
                                <p className="text">
                                    To use design system styles, import below file to your App /
                                    Component
                                </p>

                                <div className="code-container-import">
                                    <pre>
                                        <code>
                                            <div className="m-l-15">
                                                @import
                                                url("https://freyadesignsystemurl/design/treeview.css");
                                            </div>
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>



                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Tree View</h3>
                                <p className="text">
                                    Lists are continuous, vertical indexes of text or images.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20 ">
                                            <Box  className="treeview-list" sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
                                                <TreeView 
                                                    aria-label="file system navigator"
                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                >
                                                    <TreeItem nodeId="1"  label="Treeview List">
                                                    
                                                        <TreeItem nodeId="2" label="Sub Treeview List" />
                                                    </TreeItem>
                                                    <TreeItem nodeId="5" label="Item">
                                                        <TreeItem nodeId="10" label="Item 1" />
                                                        <TreeItem nodeId="6" label="Sub Item">
                                                            <TreeItem nodeId="8" label="Sub Item 1" />
                                                        </TreeItem>
                                                    </TreeItem>
                                                </TreeView>
                                            </Box>



                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={Treeviewlist} />
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>

                    <div className="row">
                        <div className="col-12 m-t-20">
                            <h3 className="sub-heading">
                                React Material UI Component Library{" "}
                            </h3>
                            <p className="text">
                                For more information, you may refer MUI component page by
                                clicking below link.
                            </p>

                            <div className="notification-main">
                                <div className="notification-content">
                                    <a
                                        href="https://mui.com/x/react-tree-view/"
                                        target="blank"
                                    >
                                        MUI Tree View
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="bookmark-navigator">
                
                </div> */}
            </div>
        </div>
    );
};

export default MuiTreeview;
