import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Landingimg from "../../src/assets/images/design-system.png";
const Landingpage = () => {
  const location = useLocation();
  return (
    <div className="landingpage">
      <section className="hero-section-wrapper">
        <div className="hero-content">
          <h1>Design, develop, deliver</h1>
          <p>
            Use Freya Fusion's end-to-end design language to create simple,
            intuitive and beautiful experiences.
          </p>
          <a alt="/get-started" href="/foundations" tabindex="0">
            <span class="css-17wby6x">Get started</span>
          </a>
        </div>
        <div className="hero-banner">
          <img
            class="css-1riai7t"
            src={Landingimg}
            height="60rem"
            width="80rem"
            alt=""
          />
        </div>
      </section>
    </div>
  );
};

export default Landingpage;
