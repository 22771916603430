import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Foundations from "./pages/foundations";
import Icons from "./pages/icons";
import Muibuttons from "./components/muibuttons";
import "./App.scss";
import Header from "./layout/header";
import UiComponents from "./pages/uicomponents";
import Muiaccordion from "./components/muiaccordion";
import Muialerts from "./components/muialerts";
import Sidebar from "./layout/sidebar";
import Foundation from "./pages/foundations.js";
import Mainuicomponentn from "./components/maincomponent";
import Muiautocomplete from "./components/muiautocomplete.js";
import Muibadge from "./components/muibadge";
import Muibreadcrumbs from "./components/muibreadcrumbs.js";
import Muicheckbox from "./components/muicheckbox.js";
import Card from "./components/card.js";
import Muidialogs from "./components/muidialogs.js";
import Muiform from "./components/muiform.js";
import Grid from "./components/grid.js";
import Muipopover from "./components/muipopover.js";
import Muisidebar from "./components/muisidebar.js";
import Muiswich from "./components/muiswitch.js";
import Muitabs from "./components/muitabs.js";
import Textareaautosize from "./components/textareaautosize.js";
import Muitooltip from "./components/muitooltip.js";
import Foundationdefault from "./foundations/foundationdefault.js";
import List from "./components/list.js";
import Colors from "./foundations/colors.js";
import Logos from "./foundations/logos.js";
import Spacing from "./foundations/spacing.js";
import Typographies from "./foundations/typographies.js";
import Menu from "./components/menu.js";
import Muidatatable from "./components/muidatatable.js";
import Muidatepicker from "./components/muidatepicker.js";
import Stepper from "./components/stepper.js";
import Sidenavigation from "./components/sidenavigation";
import Treeviewlist from "./components/treeviewlist";
import Transferlist from "./components/transferlist";
import Buttongroup from "./components/buttongroup";
import Select from "./components/select";
import Muilink from "./components/muilink";
import Typography from "./components/typography";
import Box from "./components/box";
import Muitoaster from "./components/muitoaster.js";
// import Container from "./components/container";
// import Muibasicgrid from "./components/muibasicgrid";
// import Stack from "./components/stack";
// import Muiavatar from "./components/muiavatar";
// import Muitogglebutton from "./components/muitogglebutton";
// import Muiloader from "./components/muiloader";
// import Skeleton from "./components/skeleton";
// import Slider from "./components/slider";
// import Progress from "./components/progress";


import Showlandingpage from "../src/pages/landingpage.js";
import { Avatar } from "@mui/material";

const SidebartoShowHide = () => {
  const location = useLocation();
  const showFoundationsidebar = location.pathname.startsWith("/foundations");
  const showComponentsidebar = location.pathname.startsWith("/components");
  return (
    <div>
      {showComponentsidebar && <Sidebar></Sidebar>}
      {showFoundationsidebar && <Foundations></Foundations>}
    </div>
  );
};
const ShowLanding = () => {
  const location = useLocation();
  const showLandingpages = location.pathname === "/designsystem";
  return <div>{showLandingpages && <Showlandingpage></Showlandingpage>}</div>;
};

function App() {
  return (
    <Router>
      <div>
        <Header></Header>
        <SidebartoShowHide></SidebartoShowHide>

        <div className="body-content">
          <Routes>
            <Route path="/" element={<Navigate replace to="/designsystem" />} />
            <Route path="foundations" element={<Foundationdefault />} />
            <Route path="components" element={<Mainuicomponentn />} />
            <Route path="icons" element={<Icons />} />
            <Route path="components/muiaccordion" element={<Muiaccordion />} />
            <Route path="components/muialerts" element={<Muialerts />} />
            <Route
              path="components/muiautocomplete"
              element={<Muiautocomplete />}
            />
            <Route path="components/muibadge" element={<Muibadge />} />
            <Route path="components/muibuttons" element={<Muibuttons />} />
            <Route
              path="components/muibreadcrumbs"
              element={<Muibreadcrumbs />}
            />
            <Route path="components/card" element={<Card />} />
            <Route path="components/muicheckbox" element={<Muicheckbox />} />
            <Route path="components/muidialogs" element={<Muidialogs />} />
            <Route path="components/muiform" element={<Muiform />} />
            <Route path="components/grid" element={<Grid />} />
            <Route path="components/list" element={<List />} />
            <Route path="components/menu" element={<Menu />} />
            <Route path="components/muipopover" element={<Muipopover />} />
            <Route path="components/muisidebar" element={<Muisidebar />} />
            <Route path="components/muiswitch" element={<Muiswich />} />
            <Route path="components/muitabs" element={<Muitabs />} />
            <Route
              path="components/textareaautosize"
              element={<Textareaautosize />}
            />
            <Route path="components/muitooltip" element={<Muitooltip />} />
            <Route path="foundations/colors" element={<Colors />} />
            <Route path="foundations/logos" element={<Logos />} />
            <Route path="foundations/spacing" element={<Spacing />} />
            <Route path="foundations/typographies" element={<Typographies />} />
            <Route path="components/muidatatable" element={<Muidatatable />} />
            <Route
              path="components/muidatepicker"
              element={<Muidatepicker />}
            />
            <Route path="components/stepper" element={<Stepper />} />
            <Route
              path="components/sidenavigation"
              element={<Sidenavigation />}
            />
            {/* <Route path="components/datepicker"  element={<Datepicker />} /> */}
          <Route path="components/treeviewlist" element={<Treeviewlist />} />
            <Route path="components/transferlist"  element={<Transferlist />} />
          <Route path="components/buttongroup"  element={<Buttongroup />} />
          <Route path="components/select"  element={<Select />} />
          <Route path="components/muilink"  element={<Muilink />} />
          <Route path="components/typography"  element={<Typography />} />
          <Route path="components/box"  element={<Box />} />
          {/* <Route path="components/container"  element={<Container />} />
          <Route path="components/muibasicgrid"  element={<Muibasicgrid />} />
          <Route path="components/stack"  element={<Stack />} />
          <Route path="components/muiavatar"  element={<Muiavatar />} />
          <Route path="components/muitogglebutton"  element={<Muitogglebutton />} />
          <Route path="components/muiloader"  element={<Muiloader />} />
          <Route path="components/skeleton"  element={<Skeleton />} />
          <Route path="components/slider"  element={<Slider />} />
          <Route path="components/progress"  element={<Progress />} /> */}
          {/* <Route path="uRIMS-Library/grid" element={<Grid/>} />
        <Route path="checkbox" element={<Checkbox/>} />
        <Route path="buttons" element={<Buttons/>} />
        <Route path="popover" element={<Popover/>} />
        <Route path="toaster" element={<Toaster/>} />
        <Route path="form" element={<Form/>} />
        <Route path="badges" element={<Badges/>} />
        <Route path="breadcrumbs" element={<Breadcrumbs/>} />
        <Route path="muicomponents" element={<MuiComponents/>} /> */}
            <Route path="components/muitoaster" element={<Muitoaster />} />
          </Routes>
        </div>
        <ShowLanding></ShowLanding>
      </div>
    </Router>
  );
}

export default App;
