import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Canadahealth from "../assets/images/canadahealth.png";

import CollapsibleCode from "./CodeBlockcode";

import "../designsystem/scss/components/_muibadge.scss";
import { CopyBlock, dracula } from "react-code-blocks";
const label = { inputProps: { "aria-label": "Switch demo" } };
const Muicardcomponent = () => {



  // Active class for bookmarks
  const [activeElement, setActiveElement] = useState(0);

  const handleItemClick = (index) => {
    setActiveElement(index);
  };
  const items = ["Option-1", "Option-2","Option-3"];


  // React Code Block Starts
  const [language, changeLanguage] = useState("js");
  const [lineNumbers, toggleLineNumbers] = useState(true);
  const cardCode = ` // To use mui card import card component from mui // 
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
    
// React MUI component code// 
    export default function BasicCard() {
      return (
        <Card>
        <CardActions>
          <span className="card-action-link"> ID-123 </span>
          <span className=" ">  <em className="icon-dots-three-vertical"></em></span>
        </CardActions>
        <CardContent>
          <Typography variant="h5" component="div">
          <span>       Card </span>
          </Typography>
          <Typography variant="body1">
          <span>     Lorem ipsum is typically a corrupted version of De
            finibus bonorum et malorum.Lorem ipsum is typically a corrupted version of De
            finibus bonorum et malorum. Lorem ipsum is typically a corrupted version of De
            finibus bonorum et malorum. </span>
          </Typography>

        </CardContent>

      </Card>
      );
    } 
  `;

  const cardCodetwo = ` // To use mui card import card component from mui // 
      import Box from '@mui/material/Box';
      import Card from '@mui/material/Card';
      import CardActions from '@mui/material/CardActions';
      import CardContent from '@mui/material/CardContent';
      import Button from '@mui/material/Button';
      import Typography from '@mui/material/Typography';
          
      // React MUI component code// 
          export default function BasicCard() {
            return (
              <Card>
              <CardActions>
                <span className="card-action-link"> Medicine </span>
                <span className=" ">  <em className="icon-dots-three-vertical"></em></span>
              </CardActions>
              <CardContent>
                <Typography variant="h5" component="div">
                  <span>    Paracetamol </span>
                </Typography>
                <Typography variant="body2">
                  <span>     Paracetamol 500 is a medicine used to treat mild to moderate pain. </span>
                </Typography>

                <Typography variant="body3">
                  <span>     Dosage form </span>
                </Typography>
                <Typography variant="h6" component="div">
                  <span>    Tablet </span>
                </Typography>

              </CardContent>

            </Card>
            );
          } 
 `;
  const cardCodethree = ` // To use mui card import card component from mui // 
            import Box from '@mui/material/Box';
            import Card from '@mui/material/Card';
            import CardActions from '@mui/material/CardActions';
            import CardContent from '@mui/material/CardContent';
            import Button from '@mui/material/Button';
            import Typography from '@mui/material/Typography';
                
            // React MUI component code// 
                export default function BasicCard() {
                  return (
                    <Card>
                    <CardActions>
                      <span className="card-count"><em className="icon-round-1"></em> 2 </span>
                      <span class=" ">  <em class="icon-dots-three-vertical"></em></span>

                    </CardActions>
                    <CardContent>

                      <center>
                        <img src={Canadahealth} height="auto" width="100%" />
                        <Typography variant="h5" component="div">
                          <span>   Paracetamol</span>
                        </Typography>
                        <Typography variant="body4">
                          <span>  Paracetamol 500 is a medicine used to treat mild to moderate pain.</span>
                        </Typography>
                        <span className="card-link"> Medicine </span>
                      </center>

                    </CardContent>
                    <div className="row">
                      <div className="col-12">
                        <CardContent>
                          <Typography variant="body5">

                            <span> Category </span>
                          </Typography>
                          <Typography variant="h4" component="div">
                            <span> Local</span>


                          </Typography>

                        </CardContent>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <CardContent>
                          <Typography variant="body5">
                            Received Date
                          </Typography>
                          <Typography variant="h4" component="div">
                            <span> 01-Jan-2023</span>
                          </Typography>

                        </CardContent>
                      </div>
                      <div className="col-6">
                        <CardContent>
                          <Typography variant="body5">
                            Due Date
                          </Typography>
                          <Typography variant="h4" component="div">
                            <span> 01-Jan-2024</span>
                          </Typography>

                        </CardContent>
                      </div>
                    </div>

                  </Card>                
                       );
                } 
 `;
  // React Code Block End here

  // Code for collapse Starts
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Code for collapse End here

  const card = {
    code: (
      <CopyBlock
        language={language}
        text={cardCode}
        showLineNumbers={lineNumbers}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
    ),
  };

  const cardTwo = {
    code: (
      <CopyBlock
        language={language}
        text={cardCodetwo}
        showLineNumbers={lineNumbers}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
    ),
  };
  const cardThree = {
    code: (
      <CopyBlock
        language={language}
        text={cardCodethree}
        showLineNumbers={lineNumbers}
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
    ),
  };
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  return (
    <div>
      <div className="content-header">
        <h1 className="heading"> Card </h1>

        <p className="text m-b-0">
          A card is a square or rectangular container that can contain any kind
          of content. Cards symbolize units of information, and each one acts as
          an entry point for users to access more details. Cards may also be
          used in data displays like card views, or for positioning content on a
          page.
        </p>
      </div>
      <div className="content-wrapper">
        <div className="content-area">
          <div className="row">
            <div className="col-12">
              <section>
                <h3 className="sub-heading">Component Usage </h3>
                <p className="text">
                  To use design system styles, import below file to your App /
                  Component
                </p>

                <div className="code-container-import">
                  <pre>
                    <code>
                      <div className="m-l-15">
                        @import
                        url("https://freyadesignsystemurl/design/card.css");
                      </div>
                    </code>
                  </pre>
                </div>
              </section>
            </div>
          </div>

          <hr className="section-border-bottom"></hr>

          {/* Card Section Starts here */}
          <div className="row" id="Option-1">
            <div className="col-12 ">
              <section>
                <h3 className="sub-heading">Card Option 1</h3>
                <p className="text">
                  Cards typically have a CardTitle,CardBody and CardFooter. You may omit these components as needed, but it is recommended to at least include a CardBody to provide details about the card item.
                </p>
                <div className="collapsible-code">
                  <div className="row ">
                    <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20 ">
                      <Card>
                        <CardActions>
                          <span className="card-action-link"> ID-123 </span>
                          <span className=" ">  <em className="icon-dots-three-vertical"></em> </span>
                        </CardActions>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            <span>       Card </span>
                          </Typography>
                          <Typography variant="body1">
                            <span>     Lorem ipsum is typically a corrupted version of De
                              finibus bonorum et malorum.Lorem ipsum is typically a corrupted version of De
                              finibus bonorum et malorum. Lorem ipsum is typically a corrupted version of De
                              finibus bonorum et malorum. </span>
                          </Typography>

                        </CardContent>

                      </Card>
                    </div>
                  </div>
                  <CollapsibleCode codeItem={card} />
                </div>
              </section>
            </div>
          </div>
          {/* Card Section Ends here */}


          {/* Card  two Section Starts here */}
          <div className="row" id="Option-2">
            <div className="col-12 ">
              <section>
                <h3 className="sub-heading">Card Option 2</h3>
                <p className="text">
                  Cards typically have a CardTitle,CardBody and CardFooter. You may omit these components as needed, but it is recommended to at least include a CardBody to provide details about the card item.
                </p>
                <div className="collapsible-code">
                  <div className="row ">
                    <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20 ">
                      <Card>
                        <CardActions>
                          <span className="card-action-link"> Medicine </span>
                          <span className=" ">  <em className="icon-dots-three-vertical"></em></span>
                        </CardActions>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            <span>    Paracetamol </span>
                          </Typography>
                          <Typography variant="body2">
                            <span>     Paracetamol 500 is a medicine used to treat mild to moderate pain. </span>
                          </Typography>

                          <Typography variant="body3">
                            <span>     Dosage form </span>
                          </Typography>
                          <Typography variant="h6" component="div">
                            <span>    Tablet </span>
                          </Typography>

                        </CardContent>

                      </Card>
                    </div>
                  </div>
                  <CollapsibleCode codeItem={cardTwo} />
                </div>
              </section>
            </div>
          </div>
          {/* Card two Section Ends here */}

          {/* Card three Section Starts here */}
          <div className="row" id="Option-3">
            <div className="col-12 ">
              <section>
                <h3 className="sub-heading">Card Option 3</h3>
                <p className="text">
                  Cards typically have a CardTitle,CardBody and CardFooter. You may omit these components as needed, but it is recommended to at least include a CardBody to provide details about the card item.
                </p>
                <div className="collapsible-code">
                  <div className="row ">
                    <div className=" m-t-20 m-l-20 m-b-20 m-r-20 ">
                      <div className="col-4">                   
                         <Card>
                        <CardActions>
                          <span className="card-count"><em className="icon-round-1"></em> 2 </span>
                          <span class=" ">  <em class="icon-dots-three-vertical"></em></span>

                        </CardActions>
                        <CardContent>

                          <center>
                            <img src={Canadahealth} height="auto" width="100%" />
                            <Typography variant="h5" component="div">
                              <span>   Paracetamol</span>
                            </Typography>
                            <Typography variant="body4">
                              <span>  Paracetamol 500 is a medicine used to treat mild to moderate pain.</span>
                            </Typography>
                            <span className="card-link"> Medicine </span>
                          </center>

                        </CardContent>
                        <div className="row">
                          <div className="col-12">
                            <CardContent>
                              <Typography variant="body5">

                                <span> Category </span>
                              </Typography>
                              <Typography variant="h4" component="div">
                                <span> Local</span>


                              </Typography>

                            </CardContent>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <CardContent>
                              <Typography variant="body5">
                                Received Date
                              </Typography>
                              <Typography variant="h4" component="div">
                                <span> 01-Jan-2023</span>
                              </Typography>

                            </CardContent>
                          </div>
                          <div className="col-6">
                            <CardContent>
                              <Typography variant="body5">
                                Due Date
                              </Typography>
                              <Typography variant="h4" component="div">
                                <span> 01-Jan-2024</span>
                              </Typography>

                            </CardContent>
                          </div>
                        </div>

                      </Card>
                      </div>

                    </div>
                  </div>
                  <CollapsibleCode codeItem={cardThree} />
                </div>
              </section>
            </div>
          </div>
          {/* Card three Section Ends here */}

          <hr className="section-border-bottom"></hr>

          <div className="row">
            <div className="col-12 m-t-20">
              <h3 className="sub-heading">
                React Material UI Component Library{" "}
              </h3>
              <p className="text">
                For more information, you may refer MUI component page by
                clicking below link.
              </p>

              <div className="notification-main">
                <div className="notification-content">
                  <a
                    href="https://mui.com/material-ui/react-card/"
                    target="blank"
                  >
                    MUI Card
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmark-navigator">
          <h6> CONTENTS </h6>
       
          <ul>
            {items.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(index)}
                className={activeElement === index ? "active" : ""}
              >
                <a href={"#" + item}> {item}</a>
              </li>
            ))}
          </ul>
        </div>
      
      </div>{" "}
    </div>
  );
};

export default Muicardcomponent;
