import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import CollapsibleCode from "./CodeBlockcode";
import { CopyBlock, dracula } from "react-code-blocks";



const label = { inputProps: { "aria-label": "Switch demo" } };
const Muitypographycomponent = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Active class for bookmarks
    const [activeElement, setActiveElement] = useState(0);

    const handleItemClick = (index) => {
        setActiveElement(index);
    };
    const items = ["Default", "Primary", "Outlined", "Link Button"];

    // React Code Block Starts
    const [language, changeLanguage] = useState("js");
    const [lineNumbers, toggleLineNumbers] = useState(true);
    const muitypographyHeadingCodeOne = ` // To use mui typography import typography component from mui // 
 import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
  
  
   // React MUI component code// 
   export default function BasicTypography() {
    return (

        <Box>
        <Typography variant="h1" gutterBottom>
        Heading Level 1
    </Typography>
    </Box>
  
    
        );
    } 
  `;
    const muitypographyHeadingCodeTwo = ` // To use mui typography import typography component from mui // 
  import Box from '@mui/material/Box';
 import Typography from '@mui/material/Typography';
   
   
    // React MUI component code// 
    export default function BasicTypography() {
     return (
 
         <Box  >
         <Typography variant="h2" gutterBottom>
         Heading Level 2
         </Typography>
     </Box>
   
     
         );
     } 
   `;
    const muitypographyHeadingCodeThree = ` // To use mui typography import typography component from mui // 
   import Box from '@mui/material/Box';
  import Typography from '@mui/material/Typography';
    
    
     // React MUI component code// 
     export default function BasicTypography() {
      return (
  
          <Box  >
          <Typography variant="h3" gutterBottom>
          Heading Level 3
      </Typography>
      </Box>
    
      
          );
      } 
    `;
    const muidisplayHeadingCodeOne = ` // To use mui typography import typography component from mui // 
    import Box from '@mui/material/Box';
   import Typography from '@mui/material/Typography';
     
     
      // React MUI component code// 
      export default function BasicDisplayTypography() {
       return (
   
           <Box >
           <Typography variant="h4" gutterBottom>
           Display Level 1
       </Typography>
       </Box>
     
       
           );
       } 
     `;
    const muidisplayHeadingCodeTwo = ` // To use mui typography import typography component from mui // 
     import Box from '@mui/material/Box';
    import Typography from '@mui/material/Typography';
      
      
       // React MUI component code// 
       export default function BasicDisplayTypography() {
        return (
    
            <Box  >
            <Typography variant="h5" gutterBottom>
            Display Level 2
        </Typography>
        </Box>
      
        
            );
        } 
      `;

    const muifontweightsCode = ` // To use mui typography import typography component from mui // 
        import Box from '@mui/material/Box';
       import Typography from '@mui/material/Typography';
         
         
          // React MUI component code// 
          export default function BasicfontWeightTypography() {
           return (
       
            <Box >
            <Typography  className="heading-typography" gutterBottom >
                Heading
            </Typography>

        </Box>
        <Box>
            <Typography variant="h6" gutterBottom >
                 Sub Heading
            </Typography>

        </Box>
        <Box >
            <Typography variant="subtitle2" gutterBottom >
                 Subtitle
            </Typography>

        </Box>
        <Box  >
            <Typography variant="body1" gutterBottom>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </Typography>

        </Box>
         
           
               );
           } 
         `;

       
         const muifontweightstypesCode = ` // To use mui typography import typography component from mui // 
         import Box from '@mui/material/Box';
        import Typography from '@mui/material/Typography';
          
          
           // React MUI component code// 
           export default function BasicfontWeightTypography() {
            return (
        
                <Box  >
                <Typography variant="body1" className="regular" >
                    Regular (400): Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>

            </Box>
            <Box >
                <Typography variant="body1" className="bold" gutterBottom >
                    Bold (500):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>

            </Box>
            <Box>
                <Typography variant="body1" className="bolder" gutterBottom >
                    Bolder (600):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>

            </Box>

            <Box >
                <Typography  variant="body1" className="boldest" gutterBottom >
                    Boldest (650):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>

            </Box>   
                );
            } 
          `;



    const muiformviewCode = ` // To use mui typography import typography component from mui // 
  import Box from '@mui/material/Box';
 import Typography from '@mui/material/Typography';
   
   
    // React MUI component code// 
    export default function BasicTypography() {
     return (
        <div className="form-group">
        <label class="form-label">
            Label Name
        </label>
        <span className="form-content" >
            Lorem ipsum dolor sit amet.
        </span>
    </div>
   
     
         );
     } 
   `;

    const muibodytextCode = ` // To use mui typography import typography component from mui // 
   import Box from '@mui/material/Box';
  import Typography from '@mui/material/Typography';
    
    
     // React MUI component code// 
     export default function BasicTypography() {
      return (
  
        <Box>
        <Typography variant="body2" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
            blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
            neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam.
        </Typography>

    </Box>
    
      
          );
      } 
    `;
    const muispantextCode = ` // To use mui typography import typography component from mui // 
    import Box from '@mui/material/Box';
   import Typography from '@mui/material/Typography';
     
     
      // React MUI component code// 
      export default function BasicTypographySpan() {
       return (
   
        <Box>
        <Typography >
            <span>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </span>
   
        </Typography>

    </Box>
     
       
           );
       } 
     `;

    // React Code Block End here

    // Code for collapse Starts
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Code for collapse End here

    const typographyHeadingOne = {
        code: (
            <CopyBlock
                language={language}
                text={muitypographyHeadingCodeOne}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const typographyHeadingTwo = {
        code: (
            <CopyBlock
                language={language}
                text={muitypographyHeadingCodeTwo}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const typographyHeadingThree = {
        code: (
            <CopyBlock
                language={language}
                text={muitypographyHeadingCodeThree}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const displayHeadingOne = {
        code: (
            <CopyBlock
                language={language}
                text={muidisplayHeadingCodeOne}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const displayHeadingTwo = {
        code: (
            <CopyBlock
                language={language}
                text={muidisplayHeadingCodeTwo}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };

    const fontweights = {
        code: (
            <CopyBlock
                language={language}
                text={muifontweightsCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const fontweighttypes = {
        code: (
            <CopyBlock
                language={language}
                text={muifontweightstypesCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const formview = {
        code: (
            <CopyBlock
                language={language}
                text={muiformviewCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const bodytext = {
        code: (
            <CopyBlock
                language={language}
                text={muibodytextCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };
    const spantext = {
        code: (
            <CopyBlock
                language={language}
                text={muispantextCode}
                showLineNumbers={lineNumbers}
                theme={dracula}
                wrapLines={true}
                codeBlock
            />
        ),
    };


    return (
        <div>
            <div className="content-header">
                <h1 className="heading"> Typography </h1>

                <p className="text m-b-0">
                    Typography to present your design and content as clearly and efficiently as possible.
                </p>
            </div>
            <div className="content-wrapper">
                <div className="content-area">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <h3 className="sub-heading">Component Usage </h3>
                                <p className="text">
                                    To use design system styles, import below file to your App /
                                    Component
                                </p>

                                <div className="code-container-import">
                                    <pre>
                                        <code>
                                            <div className="m-l-15">
                                                @import
                                                url("https://freyadesignsystemurl/design/tabs.css");
                                            </div>
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>

                    <hr className="section-border-bottom"></hr>

                    {/*Typography H1 Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Heading H1 </h3>
                                <p className="text">
                                    Usage: Main titles, use only once per page.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box>
                                                <Typography variant="h1" gutterBottom>
                                                    Heading Level 1
                                                </Typography>


                                            </Box>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={typographyHeadingOne} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Typography H1 Section Ends here */}

                    {/*Typography H2 Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Heading H2</h3>
                                <p className="text">
                                    Usage: Headings that identify key functionality.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box >

                                                <Typography variant="h2" gutterBottom>
                                                    Heading Level 2
                                                </Typography>


                                            </Box>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={typographyHeadingTwo} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Typography H2 Section Ends here */}

                    {/*Typography H3 Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Heading H3 </h3>
                                <p className="text">
                                    Usage: Sub-section and field group headings.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box>


                                                <Typography variant="h3" gutterBottom>
                                                    Heading Level 3
                                                </Typography>

                                            </Box>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={typographyHeadingThree} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Typography H3 Section Ends here */}




                    {/*Typography Display level -1 Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Display 1   </h3>
                                <p className="text">
                                    Display text should be used for content-based layouts such as content-heavy pages and larger marketing page layouts where larger fonts are needed.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box>


                                                <Typography variant="h4" gutterBottom>
                                                    Display Level 1
                                                </Typography>

                                            </Box>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={displayHeadingOne} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Typography Display level -1 Section Ends here */}

                    {/*Typography Display level -2 Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Display 2  </h3>
                                <p className="text">
                                    Display text should be used for content-based layouts such as news articles in page layouts.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box>


                                                <Typography variant="h5" gutterBottom>
                                                    Display Level 2
                                                </Typography>

                                            </Box>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={displayHeadingTwo} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Typography Display level -2 Section Ends here */}

    {/*Types of Font weights text Section Starts here */}
    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Types Of Font Variants </h3>
                                <p className="text">
                                    Font weight refers to the thickness or boldness of characters in a typeface.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                          
                       
                         

                                            <Box >
                                                <Typography  className="heading-typography " gutterBottom >
                                                    Heading
                                                </Typography>

                                            </Box>
                                            <Box>
                                                <Typography variant="h6" gutterBottom >
                                                     Sub Heading
                                                </Typography>

                                            </Box>
                                            <Box >
                                                <Typography variant="subtitle2" gutterBottom >
                                                     Subtitle
                                                </Typography>

                                            </Box>
                                            <Box  >
                                                <Typography variant="body1" gutterBottom>
                                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Box>




                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={fontweights} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/*Types of Font weights text  Section Ends here */}




                    {/*Types of Font weights text Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Types Of Font Weights </h3>
                                <p className="text">
                                    Font weight refers to the thickness or boldness of characters in a typeface.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box  >
                                                <Typography variant="body1" className="regular" >
                                                    Regular (400): Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Box>
                                            <Box >
                                                <Typography variant="body1" className="bold" gutterBottom >
                                                    Bold (500):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Box>
                                            <Box>
                                                <Typography variant="body1" className="bolder" gutterBottom >
                                                    Bolder (600):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Box>

                                            <Box >
                                                <Typography  variant="body1" className="boldest" gutterBottom >
                                                    Boldest (650):  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Box>




                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={fontweighttypes} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/*Types of Font weights text  Section Ends here */}


                    {/*Form View Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Form View </h3>
                                <p className="text">
                                    Usage: Form View present your design and content as clearly and efficiently as possible.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                            <div className="form-group">
                                                <label class="form-label">
                                                    Label Name
                                                </label>
                                                <span className="form-content" >
                                                    Lorem ipsum dolor sit amet.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={formview} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* Form View Section Ends here */}

                    {/* Body text Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Body Text </h3>
                                <p className="text">
                                    Usage: Body text is the main content or written information within a application, such as paragraphs, articles, or descriptions.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">

                                            <Box>
                                                <Typography variant="body2" gutterBottom>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                                                    blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                                                    neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                                                    quasi quidem quibusdam.
                                                </Typography>

                                            </Box>

                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={bodytext} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/*  Body text  Section Ends here */}


                    {/* span text Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Typography Span </h3>
                                <p className="text">
                                    Usage: The span tag is a generic inline container element. You use this element to wrap sections of text for styling purposes or to add attributes to a section of text without creating a new line of content. Same as body text.
                                </p>
                                <div className="collapsible-code">
                                    <div className="row ">
                                        <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">



                                            <Box>
                                                <Typography >
                                                    <span>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                    </span>

                                                </Typography>

                                            </Box>




                                        </div>
                                    </div>
                                    <CollapsibleCode codeItem={spantext} />
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* span text  Section Ends here */}

                    {/*Table Section Starts here */}
                    <div className="row">
                        <div className="col-12 ">
                            <section>
                                <h3 className="sub-heading"> Typography Type Styles </h3>
                                <p className="text">
                                    Usage: Typography is the art and technique of arranging type to make written language legible, readable and appealing when displayed. The arrangement of type involves selecting typefaces, point sizes, line lengths, line spacing, letter spacing, and spaces between pairs of letters.
                                </p>

                                <div className="row ">
                                    <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                        <table className="typography-table">
                                            <thead>
                                                <tr>
                                                    <th >Style</th>
                                                    <th>Properties</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width="10%" class="pt-24">Heading H1</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 20px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 650 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Line-height: 40px</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Main titles, use only once per page.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pt-24">Heading H2</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 18px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 650 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Line-height: 36px</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Headings that identify key functionality.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pt-24">Heading H3</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 16px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 600 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Line-height: 32px</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Sub-section and field group headings.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pt-24"> Display 1</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 28px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 600 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Display text should be used for content-based layouts such as content-heavy pages and larger marketing page layouts where larger fonts are needed.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pt-24">Display 2</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 24px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 600 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Display text should be used for content-based layouts such as news articles in page layouts.</td>
                                                </tr>
                                                <tr >
                                                    <td class="pt-24"> Body text</td>
                                                    <td class="pt-24"> <strong>Basic properties </strong></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Font-size: 14px</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td> Font-weight: 400 </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> Color: #262626</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td><strong>Use for</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Body text is the main content or written information within a application, such as paragraphs, articles, or descriptions.</td>
                                                </tr>
                                            </tbody>
                                        </table>



                                    </div>
                                </div>


                            </section>

                            <section>
                                <h3 className="sub-heading"> Typography Formatting </h3>


                                <div className="row ">
                                    <div className="col-12 m-t-20 m-l-20 m-b-20 m-r-20">
                                        <table className="typography-table">
                                            <thead>
                                                <tr>
                                                    <th> Style </th>
                                                    <th> Size </th>
                                                    <th> Content </th>
                                                </tr>
                                            </thead>
                                            <tbody>



                                                <tr>
                                                    <td> <strong>Bold</strong></td>
                                                    <td> 14px </td>
                                                    <td> Use strong to emphasize text.</td>
                                                </tr>
                                                <tr>
                                                    <td>   <em>Italics</em></td>
                                                    <td> 14px </td>
                                                    <td> Use em to italicize text.</td>
                                                </tr>
                                                <tr>
                                                    <td> Small </td>
                                                    <td> 11px </td>
                                                    <td> Use small primarily on help text under form fields, and as secondary supporting text in applications. It should be used sparingly.</td>
                                                </tr>
                                                <tr>
                                                    <td> <a href="#">Link</a> </td>
                                                    <td> 14px </td>
                                                    <td> Use when you are linking just a few words of text, or when a link is standalone.</td>
                                                </tr>





                                            </tbody>
                                        </table>



                                    </div>
                                </div>


                            </section>
                        </div>
                    </div>
                    {/*Table  Section Ends here */}





                    <hr className="section-border-bottom"></hr>

                    <div className="row">
                        <div className="col-12 m-t-20">
                            <h3 className="sub-heading">
                                React Material UI Component Library{" "}
                            </h3>
                            <p className="text">
                                For more information, you may refer MUI component page by
                                clicking below link.
                            </p>

                            <div className="notification-main">
                                <div className="notification-content">
                                    <a
                                        href="https://mui.com/material-ui/react-typography/"
                                        target="blank"
                                    >
                                        MUI Typography
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Muitypographycomponent;
